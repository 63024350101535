import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container } from 'react-bootstrap'
import { rhythm } from "../utils/typography"
import Img from "gatsby-image"

const Posts = () => {
  const data = useStaticQuery(graphql`
    query PostsQuery {
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {type: {eq: "blog"}}}) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              description
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 350, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const posts = data.allMarkdownRemark.edges
  return (
      <Container>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div className="post" key={node.fields.slug}>
              <Link style={{ boxShadow: `none`, color: 'var(--textNormal)', }} to={"/blog" + node.fields.slug}>
                <Img fluid={node.frontmatter.image.childImageSharp.fluid} alt={node.frontmatter.title} />
                <h2
                  style={{
                    marginTop: rhythm(1 / 4),
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                {title}  
                </h2>
              </Link>
              <small>{node.frontmatter.date}</small>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}
      </Container>
  )
}

export default Posts
